<template>
  <div>
    <a-row class="title" type="flex" justify="space-between">
      <a-col>
        <h3>
          <img
            src="@/assets/images/dashboard/customers/travels.png"
            alt="travels"
            width="15"
          />
          Viagens
        </h3>
      </a-col>

      <a-col class="filters">
        <a-button
          :class="activeTab == 'all' ? 'active' : ''"
          @click="onClickChangeContractsStatus('all')"
          type="link"
        >
          Todos
        </a-button>
        <a-divider type="vertical" />
        <a-button
          :class="activeTab == 'confirmed' ? 'active' : ''"
          @click="onClickChangeContractsStatus('confirmed')"
          type="link"
        >
          Confirmadas
        </a-button>
        <a-divider type="vertical" />
        <a-button
          :class="activeTab == 'concluded' ? 'active' : ''"
          @click="onClickChangeContractsStatus('concluded')"
          type="link"
        >
          Concluídas
        </a-button>
        <a-divider type="vertical" />
        <a-button
          :class="activeTab == 'canceled' ? 'active' : ''"
          @click="onClickChangeContractsStatus('canceled')"
          type="link"
        >
          Canceladas
        </a-button>
      </a-col>
    </a-row>

    <a-row
      v-if="!contract.loading"
      class="list-contracts mt-20"
      :gutter="[0, 20]"
    >
      <a-col
        v-for="(contract, index) in contract.list"
        class="contract"
        :key="index"
        :span="24"
      >
        <div class="wrapper">
          <a-row type="flex" justify="space-between">
            <a-col class="name">
              #{{ contractUniqueId(contract) }} -
              {{ contractedServices(contract.contracted_services) }}
            </a-col>

            <a-col class="icos">
              <img
                v-if="strToJson(contract.contracted_services).includes('hotel')"
                src="@/assets/images/dashboard/customers/double-bed.png"
                class="ml-10"
                alt="hotel"
              />
              <img
                v-if="
                  strToJson(contract.contracted_services).includes('flight')
                "
                src="@/assets/images/dashboard/customers/plane.png"
                class="ml-10"
                alt="flight"
              />
              <img
                v-if="
                  strToJson(contract.contracted_services).includes('service')
                "
                src="@/assets/images/dashboard/customers/tracking.png"
                class="ml-10"
                alt="service"
              />
            </a-col>
          </a-row>

          <a-row class="mt-10" type="flex" align="middle">
            <a-col :span="3">
              <div class="label">Status</div>
              <div class="value">
                <span v-if="activeTab == 'all'" style="color: #333">
                  <div :class="theStatus(contract).class">
                    {{ theStatus(contract).name }}
                  </div>
                  <!-- <div style="color: #8bc163">
                    {{ contract.status == "concluded" ? "Confirmado" : "" }}
                    {{ contract.status == "concluded" ? "Concluído" : "" }}
                  </div>
                  <div class="red">
                    {{ contract.status == "canceled" ? "Cancelado" : "" }}
                  </div>
                  {{ contract.status == "edit" ? "Em edição" : "" }} -->
                </span>
                <span v-if="activeTab == 'confirmed'" style="color: #ff8000">
                  Confirmado
                </span>
                <span v-if="activeTab == 'concluded'" style="color: #8bc163">
                  Concluído
                </span>
                <span v-if="activeTab == 'canceled'" class="red">
                  Cancelado
                </span>
              </div>
            </a-col>

            <a-col :span="3">
              <div class="label">Data Compra</div>
              <div class="value">
                {{
                  contract.release_date == "0000-00-00"
                    ? formatDateTime(contract.created)
                    : formatMultiDates(contract.release_date)
                }}
              </div>
            </a-col>

            <a-col :span="3">
              <div class="label">Check-in</div>
              <div class="value">
                {{
                  contract.meta.contract_first_checkin
                    ? formatMultiDates(contract.meta.contract_first_checkin)
                    : contract.meta.first_checkin
                    ? formatMultiDates(contract.meta.first_checkin)
                    : "Sem checkin"
                }}
              </div>
            </a-col>

            <a-col :span="3">
              <div class="label">Check-out</div>
              <div class="value">
                {{
                  contract.meta.contract_last_checkout
                    ? formatMultiDates(contract.meta.contract_last_checkout)
                    : contract.meta.last_checkout
                    ? formatMultiDates(contract.meta.last_checkout)
                    : "Sem checkout"
                }}
              </div>
            </a-col>

            <a-col :span="2">
              <div class="label">Adultos</div>
              <div class="value">
                {{ totalAdultsAndChds(contract).theAdults }}
              </div>
            </a-col>

            <a-col :span="2">
              <div class="label">Crianças</div>
              <div class="value">
                {{ totalAdultsAndChds(contract).theChilds }}
              </div>
            </a-col>

            <a-col :span="2">
              <div class="label">Valor</div>
              <div class="value">
                {{ formatPricePtBr(contract.value) }}
              </div>
            </a-col>

            <a-col :span="3">
              <div class="label">Consultor</div>
              <div class="value">
                {{ contract.user.first_name }} {{ contract.user.last_name }}
              </div>
            </a-col>

            <a-col :span="3">
              <a-button
                class="more f11"
                size="small"
                type="primary"
                @click="onClickOpenContractModal(contract.id)"
              >
                ver detalhes
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <div class="a-center mt-20 mb-20" v-if="contract.list.length == 0">
        <a-empty description="  Nenhum contrato encontrado." />
      </div>
    </a-row>

    <div v-if="contract.loading">
      <a-skeleton active />
      <a-skeleton active />
      <a-skeleton active />
      <a-skeleton active />
      <a-skeleton active />
    </div>

    <div class="a-center mt-10">
      <a-pagination
        show-size-changer
        :page-size.sync="contract.pagination.perPage"
        :total="contract.pagination.total"
        @change="changeContractsPage"
        @showSizeChange="changeContractsPageSize"
      />
    </div>

    <a-modal
      class="contract-details"
      width="884px"
      v-model="openContractDetailsModal"
      :title="false"
      :footer="false"
      @cancel="openContractDetailsModal = false"
    >
      <ContractModal v-if="openContractDetailsModal" :contractId="contractId" />
    </a-modal>
  </div>
</template>

<script>
import { format } from "date-fns";

import contractMixins from "@/components/contracts/mixins/contractMixins";
import ContractModal from "@/components/contracts/modals/ContractModal.vue";
import formatThings from "@/mixins/general/formatThings";

export default {
  mixins: [contractMixins, formatThings],
  components: { ContractModal },
  data() {
    return {
      activeTab: "all",
      openContractDetailsModal: false,
      contractId: "",
    };
  },
  mounted() {
    this.contract.pagination.perPage = 10;
    this.contract.filters.status.selected = "concluded";
    this.contract.filters.orderBy = "created";
    this.contract.filters.order = "desc";
    this.contract.filters.customer = this.$route.params.id;
    this.onClickChangeContractsStatus(this.activeTab);
  },
  methods: {
    onClickOpenContractModal(id) {
      this.openContractDetailsModal = true;
      this.contractId = id;
    },
    theStatus(contract) {
      let theStatus = {
        class: "",
        name: "",
      };

      if (contract.status === "canceled") {
        theStatus.class = "red";
        theStatus.name = "Cancelado";
      }
      if (contract.status === "edit") {
        theStatus.class = "";
        theStatus.name = "Em edição";
      }

      if (contract.status === "concluded") {
        if (
          ![undefined, "", "0000-00-00"].includes(
            contract.meta.contract_last_checkout
          )
        ) {
          let todayFormat = format(new Date(), "yyyy-MM-dd"),
            today = new Date(`${todayFormat} 00:00`).getTime(),
            lastCheckout = "";
          lastCheckout = contract.meta.contract_last_checkout;

          if (contract.meta.contract_last_checkout.includes("T")) {
            lastCheckout = lastCheckout.split("T")[0];
            lastCheckout = new Date(lastCheckout).getTime();
          } else {
            lastCheckout = new Date(lastCheckout).getTime();
          }

          if (today > lastCheckout == false) {
            theStatus.class = "corange";
            theStatus.name = "Confirmado";
          }

          if (today > lastCheckout == true) {
            theStatus.class = "cgreen";
            theStatus.name = "Concluído";
          }
        } else {
          theStatus.class = "cgreen";
          theStatus.name = "Concluído";
        }
      }

      return theStatus;
    },
    onClickChangeContractsStatus(status) {
      this.activeTab = status;

      if (status === "all") {
        this.contract.filters.status.selected = "";
        this.contract.filters.confirmedTravels = "";
        this.contract.filters.pendingTravels = "";
      } else {
        if (status === "canceled") {
          this.contract.filters.status.selected = status;
        } else {
          if (status === "confirmed") {
            this.contract.filters.pendingTravels = true;
            this.contract.filters.confirmedTravels = "";
          }

          if (status === "concluded") {
            this.contract.filters.confirmedTravels = true;
            this.contract.filters.pendingTravels = "";
          }

          this.contract.filters.status.selected = "concluded";
        }
      }

      this.getContracts();
    },
    totalAdultsAndChds(contract) {
      let allTravellers = [];
      if (contract.meta.travellers_rows != undefined) {
        JSON.parse(contract.meta.travellers_rows).forEach((traveller) => {
          allTravellers.push({
            id: traveller.id,
            age: `${contract.meta[`traveller_${traveller.id}_age`]}`,
          });
        });
      }

      let theAdults = allTravellers.filter((traveller) => {
        return traveller.age === "adult";
      });

      let theChilds = allTravellers.filter((traveller) => {
        return traveller.age === "child";
      });

      return { theAdults: theAdults.length, theChilds: theChilds.length };
    },
    contractUniqueId(contract) {
      let uniqueId = contract.created.split("-");
      return uniqueId[0] + uniqueId[1] + contract.id;
    },
    contractedServices(contracted_services) {
      let theContractedServices = "";

      if (contracted_services != undefined && contracted_services != "") {
        if (JSON.parse(contracted_services).length > 0) {
          JSON.parse(contracted_services).forEach((product) => {
            if (product == "hotel") {
              theContractedServices += "Hotel, ";
            }

            if (product == "flight") {
              theContractedServices += "Aéreo, ";
            }

            if (product == "service") {
              theContractedServices += "Serviço, ";
            }
          });
        } else {
          theContractedServices = "Nenhum produto   --";
        }
      }

      return theContractedServices.slice(0, -2);
    },
  },
};
</script>
<style lang="sass">
.contract-details
  .ant-modal-close
    top: -48px
    padding: 0 !important
    height: 20px
    width: 20px
    color: #FFF !important
    right: 16px
  .ant-modal-body
    padding: 0
</style>

<style lang="sass" scoped>
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  h3
    font-size: 14px
    font-weight: 500
    color: #444
  img
    margin-right: 5px
.list-contracts
  .contract
    .wrapper:hover
      box-shadow: 0px 0px 10px #ddd
    .wrapper
      padding: 15px
      border: 1px solid #eee
      border-radius: 6px
      transition: .3s
      .more
        width: 100%
        background: #6a50a7
        border: 1px solid #6a50a7
      .label
        font-size: 11px
        color: #999
      .value
        font-size: 11px
        font-weight: 600


.content
  .filters
    button
      font-size: 11px
      padding: 0
      line-height: 1 !important
      height: auto
      color: #9999
      border: 0
      transition: .3s
    button.active
      color: #992c5f  !important
  .w-box
    min-height: 577px
.actions
  .save
    padding-top: 10px
    padding-bottom: 15px
    height: 59px
    border-radius: 6px
    font-size: 20px
    border-color: #663399
    background: #663399
</style>
