import { render, staticRenderFns } from "./CustomerInfosSection.vue?vue&type=template&id=31e6b7d1&scoped=true&"
import script from "./CustomerInfosSection.vue?vue&type=script&lang=js&"
export * from "./CustomerInfosSection.vue?vue&type=script&lang=js&"
import style0 from "./CustomerInfosSection.vue?vue&type=style&index=0&id=31e6b7d1&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e6b7d1",
  null
  
)

export default component.exports