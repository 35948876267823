<template>
  <aRow class="customer">
    <a-col class="title" :span="24">
      <img
        src="@/assets/images/dashboard/contracts/customer.png"
        width="19"
        alt="Customer"
      />
      Dados básicos
    </a-col>
    <a-col class="infos" :span="24">
      <span class="img">
        <a-icon type="user" />
      </span>
      <span
        class="upper dotted-phrase"
        v-if="tempCustomer.person_type == 'Pessoa Jurídica'"
      >
        {{ tempCustomer.trading_name }}
      </span>
      <span class="upper dotted-phrase" v-else>
        {{ tempCustomer.first_name }}
        {{ tempCustomer.last_name }}
      </span>
    </a-col>
    <a-col class="infos" :span="24">
      <span class="img">
        <a-icon type="mobile" />
      </span>
      {{ tempCustomer.mobile_phone }}
      {{ tempCustomer.phone ? ` ou ${tempCustomer.phone}` : "" }}
    </a-col>
    <a-col class="infos" :span="24">
      <span class="img">
        <a-icon type="mail" />
      </span>
      {{ tempCustomer.email }}
    </a-col>
  </aRow>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  props: {
    customers: Object,
    tempCustomer: Object,
  },
  mixins: [formatThings],
};
</script>

<style lang="sass" scoped>
.customer
  .infos
    font-weight: 600
    font-size: 13px
    padding: 3px 0
    .img
      width: 20px
      display: inline-block
      text-align: center
      i
        color: #aaa
  .title
    border-bottom: 1px solid #eee
    padding: 0 0 10px
    margin: 0 0 10px
    i
      font-size: 16px
</style>
