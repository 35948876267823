<template>
  <aRow type="flex" align="top" :gutter="15">
    <aCol class="content customer-resume" flex="auto">
      <aRow class="haya-panels mb-5" :gutter="[20, 20]">
        <aCol :span="6">
          <div class="box">
            <div class="title"></div>
            <div class="total">
              {{
                contract.meta.concluded_travels
                  ? contract.meta.concluded_travels
                  : 0
              }}
            </div>
            <div class="txt">Viagens Confirmadas</div>
            <div class="footer transparent">...</div>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="box">
            <div class="title"></div>
            <div class="total">
              {{
                contract.meta.pending_travels
                  ? contract.meta.pending_travels
                  : 0
              }}
            </div>
            <div class="txt">Viagens Pendentes</div>
            <div class="footer transparent">...</div>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="box">
            <div class="title"></div>
            <div class="total">
              {{ contract.meta.total ? contract.meta.total : 0 }}
            </div>
            <div class="txt">Atendimentos</div>
            <div class="footer transparent">...</div>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="box">
            <div class="title"></div>
            <div class="total">
              {{
                contract.meta.total_profit
                  ? "R$ " + contract.meta.total_profit
                  : 0
              }}
            </div>
            <div class="txt">Lucratividade</div>
            <div class="footer">
              Valor total:
              {{
                contract.meta.total_sold ? "R$ " + contract.meta.total_sold : 0
              }}
            </div>
          </div>
        </aCol>
      </aRow>
    </aCol>
  </aRow>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";

export default {
  props: {
    customers: Object,
  },
  mixins: [formatThings, contractMixins],
  data() {
    return {};
  },
  mounted() {
    this.contract.pagination.perPage = 1;
    this.contract.filters.status.selected = "";
    this.contract.filters.orderBy = "created";
    this.contract.filters.order = "desc";
    this.contract.filters.customer = this.$route.params.id;
    this.getContracts();
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.customer-resume
  h3
    font-size: 14px
    color: #bcbec0
    margin: 0
    padding: 0 0 10px
    line-height: 1
    letter-spacing: -0.5px
  .value
    color: #59595b
    font-size: 20px
    font-weight: 600
    line-height: 1
    letter-spacing: -1px
    text-align: center
.customer-meta
  padding: 10px 17px !important
  .date
    font-size: 11px
    font-weight: 400
    letter-spacing: -1px
  .title
    h1
      font-size: 24px
      font-weight: 600
      letter-spacing: -1.5px
      max-width: 270px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      margin: 0
      padding: 0
      line-height: 1
</style>
