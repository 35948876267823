<template>
  <div>
    <FilesTable
      moduleName="customer"
      :moduleId="tempCustomer.id"
      :customerId="tempCustomer.id"
      s3Folder="customers"
      :searchCustomerId="`${tempCustomer.id}`"
    />
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import FilesTable from "@/components/general/FilesTable.vue";

export default {
  name: "CustomerFilesTab",
  props: {
    tempCustomer: Object,
  },
  components: { FilesTable },
  mixins: [formatThings],
};
</script>