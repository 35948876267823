var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',{attrs:{"type":"flex","align":"top","gutter":15}},[_c('aCol',{staticClass:"content customer-resume",attrs:{"flex":"auto"}},[_c('aRow',{staticClass:"haya-panels mb-5",attrs:{"gutter":[20, 20]}},[_c('aCol',{attrs:{"span":6}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"}),_vm._v(" "),_c('div',{staticClass:"total"},[_vm._v("\n            "+_vm._s(_vm.contract.meta.concluded_travels
                ? _vm.contract.meta.concluded_travels
                : 0)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"txt"},[_vm._v("Viagens Confirmadas")]),_vm._v(" "),_c('div',{staticClass:"footer transparent"},[_vm._v("...")])])]),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"}),_vm._v(" "),_c('div',{staticClass:"total"},[_vm._v("\n            "+_vm._s(_vm.contract.meta.pending_travels
                ? _vm.contract.meta.pending_travels
                : 0)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"txt"},[_vm._v("Viagens Pendentes")]),_vm._v(" "),_c('div',{staticClass:"footer transparent"},[_vm._v("...")])])]),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"}),_vm._v(" "),_c('div',{staticClass:"total"},[_vm._v("\n            "+_vm._s(_vm.contract.meta.total ? _vm.contract.meta.total : 0)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"txt"},[_vm._v("Atendimentos")]),_vm._v(" "),_c('div',{staticClass:"footer transparent"},[_vm._v("...")])])]),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"}),_vm._v(" "),_c('div',{staticClass:"total"},[_vm._v("\n            "+_vm._s(_vm.contract.meta.total_profit
                ? "R$ " + _vm.contract.meta.total_profit
                : 0)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"txt"},[_vm._v("Lucratividade")]),_vm._v(" "),_c('div',{staticClass:"footer"},[_vm._v("\n            Valor total:\n            "+_vm._s(_vm.contract.meta.total_sold ? "R$ " + _vm.contract.meta.total_sold : 0)+"\n          ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }