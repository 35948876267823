<template>
  <div>
    <a-row class="title" type="flex" justify="space-between">
      <a-col>
        <h3>
          <img
            src="@/assets/images/dashboard/customers/credit-letter.png"
            alt="travels"
            width="15"
          />
          Cartas de Crédito
        </h3>
      </a-col>
    </a-row>

    <aTable
      class="travel-table"
      :columns="creditLettersTableColumns"
      :data-source="creditLetter.list"
      :loading="creditLetter.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="creditLetterTableChange"
    >
      <div slot="customer" slot-scope="record">
        <div v-if="record?.customer?.id">
          <div
            class="dotted-phrase"
            style="width: 200px; display: block"
            v-if="record.customer?.person_type === 'Pessoa Física'"
          >
            {{ record.customer.id }} - {{ record.customer.first_name }}
            {{ record.customer.last_name }}
          </div>
          <div
            class="dotted-phrase"
            style="width: 100px; display: block"
            v-if="record.customer?.person_type === 'Pessoa Jurídica'"
          >
            {{ record.customer.id }} - {{ record.customer.trading_name }}
          </div>
        </div>
        <div v-else>---</div>
      </div>

      <div slot="value" slot-scope="record">
        {{ record.currency | formatCurrency }} {{ record.value | formatPrice }}
      </div>

      <div slot="available_value" slot-scope="record">
        {{ record.currency | formatCurrency }}
        {{ record.available_value | formatPrice }}
      </div>

      <div slot="spent_value" slot-scope="record">
        {{ record.currency | formatCurrency }}
        {{ record.spent_value | formatPrice }}
      </div>

      <div slot="expires_at" slot-scope="record">
        <div v-if="record.expires_at !== '0000-00-00'">
          {{ record.expires_at | formatDate }}
        </div>
        <div v-else>---</div>
      </div>

      <div slot="receipt_date" slot-scope="record">
        <div v-if="record.receipt_date !== '0000-00-00'">
          {{ record.receipt_date | formatDate }}
        </div>
        <div v-else>---</div>
      </div>

      <div slot="issuer" slot-scope="record">
        <div v-if="record.issuer.id">
          <a-tooltip>
            <template slot="title">
              {{ record.issuer.first_name }} {{ record.issuer.last_name }}
            </template>
            <span id="avatar">
              <a-avatar
                v-if="record.issuer.avatar"
                :src="record.issuer.avatar"
                :size="30"
              />
              <a-avatar
                v-else
                class="upper"
                style="color: #f56a00; background-color: #fde3cf"
              >
                {{ record.issuer.first_name.substring(0, 1)
                }}{{ record.issuer.last_name.substring(0, 1) }}
              </a-avatar>
            </span>
          </a-tooltip>
        </div>
        <div v-else><a-avatar icon="user" /></div>
      </div>

      <div slot="created" slot-scope="record">
        {{ record.created | formatDateTime }}
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a class="edit ml-15" @click="onClickOpenCreditLetterModal(record.id)">
          <a-icon slot="prefix" :type="'eye-svg'" />
        </a>
      </div>
    </aTable>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="creditLetter.pagination.page"
        :page-size.sync="creditLetter.pagination.perPage"
        :total="creditLetter.pagination.total"
        @change="changeCreditLetterPage"
        @showSizeChange="creditLetterChangePageSize"
      >
      </a-pagination>
    </div>

    <a-modal
      class="miles-card-modal credit-letter-modal"
      :width="1000"
      :footer="false"
      v-model="openCreditLetterModal"
      @cancel="onCloseCreditLetterModal"
    >
      <CreditLettersModal
        :creditLetterId="creditLetterId"
        :creditCardStatusList="creditCardStatusList"
        v-if="openCreditLetterModal"
      />
    </a-modal>
  </div>
</template>

<script>
import creditLetterReusables from "@/components/credit-letters/mixins/creditLetterReusables";
import CreditLettersModal from "@/components/credit-letters/modals/CreditLettersModal.vue";
import reusableThings from "@/mixins/general/reusableThings";

export default {
  name: "CustomerCreditLetterTab",
  props: {
    tempCustomer: Object,
  },
  components: { CreditLettersModal },
  mixins: [creditLetterReusables, reusableThings],
  data() {
    return {
      creditLetterId: "",
      openCreditLetterModal: false,
      creditCardStatusList: [],
      creditLettersTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          class: "status",
          width: 100,
          sorter: true,
        },
        {
          title: "Código",
          dataIndex: "code",
          key: "code",
          class: "code",
          width: 140,
          sorter: true,
        },
        {
          title: "Valor ",
          key: "value",
          width: 100,
          sorter: true,
          scopedSlots: { customRender: "value" },
        },
        {
          title: "Disponível",
          key: "available_value",
          width: 110,
          sorter: true,
          scopedSlots: { customRender: "available_value" },
        },
        {
          title: "Utilizado",
          key: "spent_value",
          width: 100,
          sorter: true,
          scopedSlots: { customRender: "spent_value" },
        },
        {
          title: "Validade",
          width: 120,
          scopedSlots: { customRender: "expires_at" },
        },
        {
          title: "Recebimento",
          width: 120,
          scopedSlots: { customRender: "receipt_date" },
        },
        {
          title: "Emissor",
          width: 80,
          scopedSlots: { customRender: "issuer" },
        },

        {
          title: "Cadastrado em:",
          width: 150,
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 60,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&category_id=33&per_page=300&order-by=item_order&order=ascend`
      )
      .then(({ data }) => {
        this.creditCardStatusList = data.data;
      });

    this.creditLetter.filters.customerId = this.tempCustomer.id;
    this.getCreditLetters();
  },
  methods: {
    onClickOpenCreditLetterModal(id) {
      this.creditLetterId = id;
      this.openCreditLetterModal = true;
    },
  },
};
</script>

<style lang="sass">
.credit-letter-modal
  .ant-modal-body
    min-height: 400px
</style>

<style lang="sass" scoped>
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  img
    margin-right: 5px
  h3
    font-size: 14px
    font-weight: 500
    color: #444
</style>
