<template>
  <section class="bg pg-height pd-20">
    <aForm
      class="form-contracts"
      @submit.prevent="submitCustomer"
      @onFieldsChange="updateTempCustomer"
      :form="customerForm"
    >
      <aRow align="top" :gutter="15">
        <aCol class="sidebar">
          <aRow :gutter="[20, 0]">
            <aCol class="mb-20" :span="24">
              <div class="w-box" :style="'marginTop: 53px'">
                <CustomerProfileSection
                  :customers="customers"
                  :tempCustomer="tempCustomer"
                />
              </div>
            </aCol>

            <aCol class="mb-20" :span="24">
              <div class="w-box">
                <CustomerBasicInfosSection
                  :customers="customers"
                  :tempCustomer="tempCustomer"
                />
              </div>
            </aCol>

            <aCol class="" :span="24">
              <CustomerMenuSection
                :customerForm="customerForm"
                :customers="customers"
                :tempCustomer="tempCustomer"
                :errorLog="errorLog"
                @activeTab="activeTab"
              />
            </aCol>

            <aCol class="actions" :span="24">
              <aRow :gutter="20">
                <aCol :span="24">
                  <aButton
                    class="a-center save"
                    type="primary"
                    html-type="submit"
                    block
                    :loading="loadingSubmitCustomer"
                    :disabled="false"
                  >
                    <img
                      src="@/assets/images/dashboard/contracts/save.png"
                      alt="save"
                    />
                  </aButton>
                </aCol>
              </aRow>
            </aCol>
          </aRow>
        </aCol>

        <aCol class="content" :style="'marginTop: 53px'" flex="auto">
          <CustomerInfosSection
            :customers="customers"
            :tempCustomer="tempCustomer"
          />
          <div class="w-box">
            <template v-if="activeCustomerTab == 'Dados Pessoais'">
              <div class="title">
                <h3>
                  <img
                    src="@/assets/images/dashboard/customers/user.png"
                    alt="travels"
                    width="15"
                  />
                  Dados Pessoais
                </h3>
              </div>
              <div>
                <aRow class="box box-contractor" :gutter="[20, 0]">
                  <aCol :span="24">
                    <CustomerFormV2
                      :customer="tempCustomer"
                      :customerForm="customerForm"
                      :allowEditCpf="$root.isAdmin()"
                    />
                  </aCol>
                </aRow>
              </div>
            </template>

            <CustomerOpportunitiesTab
              v-if="activeCustomerTab == 'Oportunidades'"
              :tempCustomer="tempCustomer"
            />

            <CustomerCreditLetterTab
              v-if="activeCustomerTab == 'Cartas de Crédito'"
              :tempCustomer="tempCustomer"
            />

            <CustomerFilesTab
              v-if="activeCustomerTab == 'Arquivos'"
              :tempCustomer="tempCustomer"
            />

            <template v-if="activeCustomerTab == 'Whatsapp'">
              <div class="title">
                <h3>
                  <i class="fa-brands fa-whatsapp mr-5" style="color: #999"></i>
                  Whatsapp
                </h3>
              </div>
              <WhatsappConversations
                v-if="tempCustomer.mobile_phone"
                :countryCode="55"
                :phoneNumber="tempCustomer.mobile_phone"
                :customerName="tempCustomer.first_name"
              />
            </template>

            <CustomerTravelsTab v-if="activeCustomerTab == 'Viagens'" />

            <template v-if="activeCustomerTab == 'Configurações'">
              <div class="title">
                <h3>
                  <img
                    src="@/assets/images/dashboard/customers/travels.png"
                    alt="travels"
                    width="15"
                  />
                  Configurações
                </h3>
              </div>
              <CustomerSettingsTab
                :customer="tempCustomer"
                :tempCustomer="tempCustomer"
                :customerForm="customerForm"
              />
            </template>

            <template v-if="activeCustomerTab == 'Anotações'">
              <div class="title">
                <h3>
                  <img
                    src="@/assets/images/dashboard/customers/note.png"
                    alt="travels"
                    width="15"
                  />
                  Anotações
                </h3>
              </div>
              <Notes
                module="customer"
                :moduleId="customers.details.id"
                :entry="customers.details"
              />
            </template>

            <template v-if="activeCustomerTab == 'Histórico'">
              <div class="title">
                <h3>
                  <img
                    src="@/assets/images/dashboard/customers/logs.png"
                    alt="travels"
                    width="15"
                  />
                  Logs
                </h3>
              </div>
              <LogsTimelineV2
                :id="customers.details.id"
                module="customer"
                color="green"
              />
            </template>

            <div id="live-debug" class="f12 mb-30">
              <json-viewer
                v-if="$store.state.userData.id == 1"
                class="mt-10 mb-20"
                :value="tempCustomer"
                :show-array-index="false"
                :expand-depth="1"
              ></json-viewer>
            </div>
          </div>
        </aCol>
      </aRow>
    </aForm>
  </section>
</template>

<script>
import customerMixins from "@/components/customers/mixins/customerMixins";
import CustomerInfosSection from "@/components/customers/sections/CustomerInfosSection.vue";
import CustomerProfileSection from "@/components/customers/sections/CustomerProfileSection.vue";
import CustomerBasicInfosSection from "@/components/customers/sections/CustomerBasicInfosSection.vue";
import CustomerMenuSection from "@/components/customers/sections/CustomerMenuSection.vue";
import CustomerTravelsTab from "@/components/customers/tabs/CustomerTravelsTab.vue";
import CustomerSettingsTab from "@/components/customers/tabs/CustomerSettingsTab.vue";
import CustomerFormV2 from "@/components/customers/forms/CustomerFormV2.vue";

import Notes from "@/components/general/Notes.vue";
import LogsTimelineV2 from "@/components/logs/LogsTimelineV2.vue";
import CustomerOpportunitiesTab from "@/components/customers/tabs/CustomerOpportunitiesTab.vue";
import CustomerFilesTab from "@/components/customers/tabs/CustomerFilesTab.vue";
import CustomerCreditLetterTab from "@/components/customers/tabs/CustomerCreditLetterTab.vue";
import WhatsappConversations from "@/components/general/WhatsappConversations.vue";

export default {
  components: {
    Notes,
    CustomerInfosSection,
    CustomerProfileSection,
    CustomerBasicInfosSection,
    CustomerMenuSection,
    CustomerTravelsTab,
    CustomerSettingsTab,
    CustomerFormV2,
    LogsTimelineV2,
    CustomerOpportunitiesTab,
    CustomerFilesTab,
    CustomerCreditLetterTab,
    WhatsappConversations,
  },
  mixins: [customerMixins],
  data() {
    return {
      customerForm: this.$form.createForm(this, {
        onValuesChange: this.updateTempCustomer,
      }),
      activeCustomerTab: "Dados Pessoais",
      loadingSubmitCustomer: false,
      errorLog: {
        customerData: [],
      },
    };
  },
  mounted() {
    this.getCustomer(this.$route.params.id);
  },
  methods: {
    updateTempCustomer(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempCustomer[field] = value;

      if (Array.isArray(value)) {
        this.tempCustomer[field] = JSON.stringify(value);
      }
      this.tempCustomer = { ...this.tempCustomer };
    },
    activeTab(tab) {
      this.customerForm.validateFields((err) => {
        if (err) this.errorLog.customerData = Object.keys(err);
        this.activeCustomerTab = tab;
      });
    },
    validateCustomerFieldsBeforeCloseModal() {
      this.customerForm.validateFields((err) => {
        if (!err) {
          this.openCustomerDataModal = false;
        } else {
          this.openCustomerDataModal = true;
          this.$message.warning(
            "Preencha todos campos obrigatórios antes de fechar."
          );
        }
      });
    },
    submitCustomer() {
      this.loadingSubmitCustomer = true;

      this.tempCustomer.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/customer/update", this.tempCustomer)
        .then(({ data }) => {
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        })
        .finally(() => {
          this.loadingSubmitCustomer = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.sidebar
  float: left
  width: 360px

.content
  float: left
  width: calc(100% - 360px)
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  img
    margin-right: 5px
  h3
    font-size: 14px
    font-weight: 500
    color: #444
.content
  flex: 1 1 !important
  .w-box
    min-height: 577px
.actions
  padding: 0
  .save
    height: 45px
    border-radius: 6px
    font-size: 20px
    border-color: #663399
    background: #663399
</style>
